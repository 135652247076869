<template>
  <div>
    <!-- Hamburger Button -->
    <button @click="toggleSidebar" class="fixed top-4 left-4 z-50 block lg:hidden p-2 bg-gray-800 text-white rounded focus:outline-none">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
      </svg>
    </button>

    <!-- Sidebar -->
    <div :class="sidebarClass" class="fixed top-0 left-0 h-full w-64 bg-gray-800 text-white flex flex-col items-center py-8 lg:translate-x-0 transition-transform duration-300 ease-in-out">
      <img src="/assets/img/profile-img.png" alt="Steven Eubank" class="rounded-full w-28 h-28 mb-4">
      <h1 class="text-2xl font-bold">Steven Eubank</h1>

      <!-- Social Icons -->
      <div class="flex flex-wrap justify-center mt-4 space-x-4">
        <a href="https://www.linkedin.com/in/steven-eubank-72a2316b/" target="_blank" class="w-10 h-10 flex items-center justify-center bg-gray-700 rounded-full hover:bg-blue-500 transition">
          <font-awesome-icon :icon="['fab', 'linkedin']" class="text-white" />
        </a>
        <a href="https://github.com/smeubank/" target="_blank" class="w-10 h-10 flex items-center justify-center bg-gray-700 rounded-full hover:bg-blue-500 transition">
          <font-awesome-icon :icon="['fab', 'github']" class="text-white" />
        </a>
        <a href="https://www.instagram.com/steven_eubank/" target="_blank" class="w-10 h-10 flex items-center justify-center bg-gray-700 rounded-full hover:bg-blue-500 transition">
          <font-awesome-icon :icon="['fab', 'instagram']" class="text-white" />
        </a>
      </div>
      <div class="flex justify-center mt-4 space-x-6">
        <a href="https://www.facebook.com/steven.m.eubank" target="_blank" class="w-10 h-10 flex items-center justify-center bg-gray-700 rounded-full hover:bg-blue-500 transition">
          <font-awesome-icon :icon="['fab', 'facebook']" class="text-white" />
        </a>
        <a href="https://twitter.com/steven_boKnows" target="_blank" class="w-10 h-10 flex items-center justify-center bg-gray-700 rounded-full hover:bg-blue-500 transition">
          <font-awesome-icon :icon="['fab', 'twitter']" class="text-white" />
        </a>
      </div>

      <!-- Navigation Links -->
      <nav class="mt-8 w-full px-6">
        <ul class="space-y-4">
          <li>
            <a @click="closeSidebar" href="#home" class="flex items-center space-x-2 hover:text-blue-300">
              <font-awesome-icon :icon="['fas', 'house']" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a @click="closeSidebar" href="#about" class="flex items-center space-x-2 hover:text-blue-300">
              <font-awesome-icon :icon="['fas', 'user']" />
              <span>About</span>
            </a>
          </li>
          <li>
            <a @click="closeSidebar" href="#resume" class="flex items-center space-x-2 hover:text-blue-300">
              <font-awesome-icon :icon="['fas', 'file-alt']" />
              <span>Resume</span>
            </a>
          </li>
          <li>
            <a @click="closeSidebar" href="#content" class="flex items-center space-x-2 hover:text-blue-300"
              :class="{'text-blue-300': activeSection === 'content'}">
              <font-awesome-icon :icon="['fas', 'stream']" />
              <span>Content</span>
            </a>
            <ul v-if="activeSection === 'content'" class="ml-6 mt-2 space-y-2">
              <li>
                <a @click="closeSidebar" href="#blogs" class="flex items-center space-x-2 hover:text-blue-300">
                  <font-awesome-icon :icon="['fas', 'newspaper']" />
                  <span>Blogs</span>
                </a>
              </li>
              <li>
                <a @click="closeSidebar" href="#events" class="flex items-center space-x-2 hover:text-blue-300">
                  <font-awesome-icon :icon="['fas', 'bullhorn']" />
                  <span>Events</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a @click="closeSidebar" href="#contact" class="flex items-center space-x-2 hover:text-blue-300">
              <font-awesome-icon :icon="['fas', 'envelope']" />
              <span>Contact Me</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';

const isOpen = ref(false);

const toggleSidebar = () => {
  isOpen.value = !isOpen.value;
};

const closeSidebar = () => {
  isOpen.value = false;
};

const sidebarClass = computed(() => {
  return isOpen.value ? 'translate-x-0' : '-translate-x-full';
});

// Ensure the sidebar is closed by default on mobile
onMounted(() => {
  if (window.innerWidth < 1024) {
    isOpen.value = false;
  }
});

// Sections for the sidebar
const sections = ref([
  { id: 'home', name: 'Home', icon: ['fas', 'house'] },
  { id: 'about', name: 'About', icon: ['fas', 'user'] },
  { id: 'resume', name: 'Résumé', icon: ['fas', 'file-alt'] },
  { id: 'content', name: 'Content', icon: ['fas', 'stream'] }, // Updated icon for Content
  { id: 'contact', name: 'Contact Me', icon: ['fas', 'envelope'] },
]);

// Track the active section
const activeSection = ref('');

const setActiveSection = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeSection.value = entry.target.id;
    }
  });
};

onMounted(() => {
  const observer = new IntersectionObserver(setActiveSection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  });

  sections.value.forEach(section => {
    const element = document.getElementById(section.id);
    if (element) {
      observer.observe(element);
    }
  });
});
</script>

<style scoped>
/* Custom styles for the sidebar and hamburger menu */
button {
  z-index: 50;
}

div.fixed {
  z-index: 40;
}

.text-blue-300 {
  font-weight: bold;
  transition: color 0.3s ease;
}
</style>
